import NoFoundPage from '@/pages/404';
import ErrorPage from '@/pages/error';
import { getHistory, PageLoading } from '@propify-tenant-client/common';
import type { PropifyRoute } from '@propify-tenant-client/layouts';
import { SentryService } from '@propify-tenant-client/services';
import type { Update } from 'history';
import type { FC } from 'react';
import { lazy, Suspense, useLayoutEffect } from 'react';
import ReactGA from 'react-ga';
import { Navigate, Outlet, useLocation, useNavigate, useRouteError } from 'react-router';
import type { RouteObject } from 'react-router-dom';

const TermsAndConditions = lazy(() =>
  import('@propify-tenant-client/common').then((module) => ({
    default: module.TermsAndConditionsPage,
  })),
);
const ApplicationLayoutWrapper = lazy(() => import('@/layouts/ApplicationLayoutWrapper'));
const ApplicationWizardLayoutWrapper = lazy(
  () => import('@/layouts/ApplicationWizardLayoutWrapper'),
);
const BasicLayoutWrapper = lazy(() => import('@/layouts/BasicLayoutWrapper'));
const UserLayoutWrapper = lazy(() => import('@/layouts/UserLayoutWrapper'));
const DocumentLayout = lazy(() =>
  import('@propify-tenant-client/layouts').then((module) => ({ default: module.DocumentLayout })),
);
const PaymentLayoutWrapper = lazy(() => import('@/layouts/PaymentLayoutWrapper'));
const SecurityLayout = lazy(() => import('@/layouts/SecurityLayoutWrapper'));
const AdditionalUsersPage = lazy(() => import('@/pages/additional-users/AdditionalUsersPage'));
const AlmostDone = lazy(() =>
  import('@propify-tenant-client/account').then((module) => ({ default: module.AlmostDone })),
);
const Dashboard = lazy(() => import('@/pages/dashboard'));
const DeactivateLead = lazy(() =>
  import('@propify-tenant-client/units').then((module) => ({
    default: module.DeactivateLead,
  })),
);
const PrivacyPoliciesPage = lazy(() =>
  import('@propify-tenant-client/common').then((module) => ({
    default: module.PrivacyPoliciesPage,
  })),
);
const SMSTermsOfService = lazy(() =>
  import('@propify-tenant-client/common').then((module) => ({
    default: module.SMSTermsOfServicePage,
  })),
);
const LeadQualificationPage = lazy(() => import('@/pages/form/LeadQualificationPage'));
const Home = lazy(() => import('@/pages/Home'));
const LockCodesPage = lazy(() => import('@/pages/lock-codes/LockCodesPage'));
const CreateWorkOrderPage = lazy(() => import('@/pages/maintenance/CreateWorkOrderPage'));
const MaintenanceRequestDetailsPage = lazy(
  () => import('@/pages/maintenance/MaintenanceRequestDetailsPage'),
);
const RequestsPage = lazy(() => import('@/pages/maintenance/MaintenanceRequestsPage'));
const AutopayPage = lazy(() => import('@/pages/payment/AutopayPage'));
const BraintreeClientPage = lazy(() => import('@/pages/payment/BraintreeClientPage'));
const MakePaymentPage = lazy(() => import('@/pages/payment/MakePaymentPage'));
const PaymentHistoryPage = lazy(() =>
  import('@propify-tenant-client/payments').then((module) => ({
    default: module.PaymentHistory,
  })),
);
const PaymentMethodsPage = lazy(() => import('@/pages/payment/payment-methods/PaymentMethodsPage'));
const CommonDoorPage = lazy(() =>
  import('@propify-tenant-client/showings').then((module) => ({
    default: module.CommonDoor,
  })),
);
const ShowingCompletedPage = lazy(() =>
  import('@propify-tenant-client/showings').then((module) => ({
    default: module.ShowingCompleted,
  })),
);
const ContactSupportPage = lazy(() =>
  import('@propify-tenant-client/showings').then((module) => ({
    default: module.ContactSupport,
  })),
);
const PreShowingFraudPreventionPage = lazy(
  () => import('@/pages/showing/check-in/PreShowingFraudPreventionPage'),
);
const UnlockCodePage = lazy(() =>
  import('@propify-tenant-client/showings').then((module) => ({
    default: module.UnlockCode,
  })),
);
const UnlockUnit = lazy(() =>
  import('@propify-tenant-client/showings').then((module) => ({
    default: module.UnlockUnit,
  })),
);
const EditShowingPage = lazy(() => import('@/pages/showing/EditShowingPage'));
const PastShowingsPage = lazy(() => import('@/pages/showing/PastShowingsPage'));
const ScheduleShowingPage = lazy(() => import('@/pages/showing/ScheduleShowingPage'));
const ShowingSuccessPage = lazy(() =>
  import('@propify-tenant-client/showings').then((module) => ({
    default: module.ShowingSuccess,
  })),
);
const UpcomingShowingsPage = lazy(() => import('@/pages/showing/UpcomingShowingsPage'));
const UnlockShowing = lazy(() =>
  import('@propify-tenant-client/showings').then((module) => ({
    default: module.UnlockShowing,
  })),
);
const SystemsLockPage = lazy(() =>
  import('@propify-tenant-client/smart-home-systems').then((module) => ({
    default: module.SystemsLock,
  })),
);
const SystemsThermostatPage = lazy(() =>
  import('@propify-tenant-client/smart-home-systems').then((module) => ({
    default: module.SystemsThermostat,
  })),
);
const TemperatureProfilesPage = lazy(
  () => import('@/pages/temperature-profiles/TemperatureProfilesPage'),
);
const ThermostatSchedulePage = lazy(() => import('@/pages/thermostat/ThermostatSchedulePage'));
const UnitDetailsPage = lazy(() => import('@/pages/unit/UnitDetailsPage'));
const UnitSearchPage = lazy(() => import('@/pages/unit/UnitSearchPage'));
const CreateUnitApplicationPage = lazy(() =>
  import('@propify-tenant-client/applications').then((module) => ({
    default: module.CreateUnitApplication,
  })),
);
const UnitApplicationsPage = lazy(() =>
  import('@propify-tenant-client/applications').then((module) => ({
    default: module.UnitApplications,
  })),
);
const UnitApplicationProcessPage = lazy(
  () => import('@/pages/unit-applications/UnitApplicationProcessPage'),
);
const AccountsHomePage = lazy(() => import('@/pages/user/home/AccountsHomePage'));
const LoginPage = lazy(() => import('@/pages/user/login/LoginPage'));
const PasswordResetConfirmPage = lazy(
  () => import('@/pages/user/passwordReset/PasswordResetConfirmPage'),
);
const PasswordResetRequestPage = lazy(
  () => import('@/pages/user/passwordReset/PasswordResetRequestPage'),
);
const VerifyEmailPage = lazy(() => import('@/pages/user/verification/email/VerifyEmailPage'));
const VerifyTokenPage = lazy(() => import('@/pages/user/verification/email/VerifyTokenPage'));
const VerifyPersonaPage = lazy(() => import('@/pages/user/verification/persona/VerifyPersonaPage'));
const RevalidateMessagePage = lazy(
  () => import('@/pages/user/verification/revalidate/RevalidateMessagePage'),
);
const VerifyPhonePage = lazy(() => import('@/pages/user/verification/sms/VerifyPhonePage'));
const WaitingListPage = lazy(() => import('@/pages/waiting/WaitingListPage'));
const RenewalApplicationProcessPage = lazy(
  () => import('@/pages/renewal-applications/RenewalApplicationProcessPage'),
);
const RenewalTermsPage = lazy(() => import('@/pages/renewal-applications/RenewalTermsPage'));
const RenewalsPage = lazy(() =>
  import('@propify-tenant-client/renewals').then((module) => ({
    default: module.Renewals,
  })),
);

export const routes: PropifyRoute[] = [
  {
    path: '/user',
    component: UserLayoutWrapper,
    routes: [
      {
        path: '/user',
        redirect: '/user/login',
      },
      {
        path: '/user/login',
        component: LoginPage,
      },
      {
        path: '/user/password-reset/confirm',
        component: PasswordResetConfirmPage,
      },
      {
        path: '/user/password-reset',
        component: PasswordResetRequestPage,
      },
    ],
  },
  {
    path: '/deactivate-lead',
    component: DeactivateLead,
  },
  {
    path: '/showings/unlock',
    component: UnlockShowing,
  },
  {
    path: '/documents',
    component: DocumentLayout,
    routes: [
      {
        path: '/documents/privacy-policy',
        component: PrivacyPoliciesPage,
      },
      {
        path: '/documents/terms-and-conditions',
        component: TermsAndConditions,
      },
      {
        path: '/documents/sms-terms-of-service',
        component: SMSTermsOfService,
      },
    ],
  },
  {
    path: '/units',
    component: BasicLayoutWrapper,
    routes: [
      {
        path: '/units',
        component: UnitSearchPage,
      },
      {
        path: '/units/:id',
        component: UnitDetailsPage,
      },
    ],
  },
  {
    path: '/',
    component: SecurityLayout,
    routes: [
      {
        path: '/',
        component: BasicLayoutWrapper,
        routes: [
          {
            path: '/lead-qualification-form',
            component: LeadQualificationPage,
          },
          {
            path: '/dashboard',
            component: Dashboard,
            requirements: ['emailVerified'],
          },
          {
            path: '/',
            component: Home,
            requirements: ['emailVerified'],
          },
          {
            path: '/account',
            routes: [
              {
                path: '/account',
                component: AccountsHomePage,
              },
              {
                path: '/account/verification/sms',
                component: VerifyPhonePage,
              },
              {
                path: '/account/verification/email/:token',
                component: VerifyTokenPage,
              },
              {
                path: '/account/verification/email',
                component: VerifyEmailPage,
              },
              {
                path: '/account/verification/revalidate',
                component: RevalidateMessagePage,
              },
              {
                path: '/account/verification/payments',
                component: BraintreeClientPage,
              },
              {
                path: '/account/verification/persona',
                component: VerifyPersonaPage,
              },
            ],
          },
          {
            path: '/showings',
            requirements: ['emailVerified', 'isProspect'],
            routes: [
              {
                path: '/showings/upcoming',
                component: UpcomingShowingsPage,
              },
              {
                path: '/showings/past',
                component: PastShowingsPage,
              },
              {
                path: '/showings/success',
                component: ShowingSuccessPage,
              },
              {
                path: '/showings/edit',
                component: EditShowingPage,
              },
              {
                path: '/showings/check-in/common-door',
                component: CommonDoorPage,
              },
              {
                path: '/showings/check-in/pre-showing',
                component: PreShowingFraudPreventionPage,
              },
              {
                path: '/showings/check-in/unlock-code',
                component: UnlockCodePage,
              },
              {
                path: '/showings/check-in/unlock-unit',
                component: UnlockUnit,
              },
              {
                path: '/showings/check-in/contact-support',
                component: ContactSupportPage,
              },
              {
                path: '/showings/check-in/completed',
                component: ShowingCompletedPage,
              },
              {
                path: '/showings/schedule',
                component: ScheduleShowingPage,
              },
            ],
          },
          {
            path: '/systems',
            requirements: ['emailVerified', 'hasSmartHomeEnabled'],
            routes: [
              {
                path: '/systems/:id/lock',
                component: SystemsLockPage,
              },
              {
                path: '/systems/:id/thermostat',
                component: SystemsThermostatPage,
              },
            ],
          },
          {
            path: '/waiting',
            requirements: ['emailVerified', 'isProspect'],
            routes: [
              {
                path: '/waiting',
                component: WaitingListPage,
              },
            ],
          },
          {
            path: '/thermostat',
            requirements: ['hasSmartHomeEnabled', 'emailVerified'],
            routes: [
              {
                path: '/thermostat/schedule',
                component: ThermostatSchedulePage,
              },
            ],
          },
          {
            path: '/maintenance',
            requirements: ['hasMaintenanceEnabled', 'emailVerified'],
            routes: [
              {
                path: '/maintenance/requests',
                component: RequestsPage,
              },
              {
                path: '/maintenance/requests/create',
                component: CreateWorkOrderPage,
              },
              {
                path: '/maintenance/requests/:id',
                component: MaintenanceRequestDetailsPage,
              },
            ],
          },
          {
            path: '/lock-codes',
            component: LockCodesPage,
            requirements: ['hasSmartHomeEnabled', 'emailVerified'],
          },
          {
            path: '/temperature-profiles',
            component: TemperatureProfilesPage,
            requirements: ['hasSmartHomeEnabled', 'emailVerified'],
          },
          {
            path: '/almost',
            component: AlmostDone,
            requirements: ['emailVerified'],
          },
          {
            path: '/additional-users',
            component: AdditionalUsersPage,
            requirements: ['hasSmartHome', 'emailVerified'],
          },
          {
            path: '/payments',
            component: PaymentLayoutWrapper,
            requirements: ['emailVerified', 'isResident'],
            routes: [
              {
                path: '/payments/make-payment',
                component: MakePaymentPage,
              },
              {
                path: '/payments/autopay',
                component: AutopayPage,
                requirements: ['hasAutopayEnabled'],
              },
              {
                path: '/payments/payment-methods',
                component: PaymentMethodsPage,
              },
              {
                path: '/payments/payment-history',
                component: PaymentHistoryPage,
              },
            ],
          },
          {
            path: '/renewal-terms/:renewalId',
            component: RenewalTermsPage,
          },
        ],
      },
      {
        path: '/renewals',
        requirements: ['emailVerified'],
        routes: [
          {
            path: '/renewals/:id',
            component: ApplicationWizardLayoutWrapper,
            routes: [
              {
                path: '/renewals/:id',
                component: RenewalApplicationProcessPage,
              },
            ],
          },
          {
            path: '/renewals',
            component: ApplicationLayoutWrapper,
            routes: [
              {
                path: '/renewals/',
                component: RenewalsPage,
              },
            ],
          },
        ],
      },
      {
        path: '/',
        component: SecurityLayout,
        routes: [
          {
            path: '/',
            component: BasicLayoutWrapper,
            routes: [
              {
                path: '/renewal-terms/:renewalId',
                component: RenewalTermsPage,
              },
            ],
          },
        ],
      },
      {
        path: '/unit-applications',
        component: ApplicationWizardLayoutWrapper,
        requirements: ['emailVerified'],
        routes: [
          {
            path: '/unit-applications/unit/:unitId/create',
            component: CreateUnitApplicationPage,
            requirements: ['emailVerified'],
          },
          {
            path: '/unit-applications/:id',
            component: UnitApplicationProcessPage,
            requirements: ['emailVerified'],
          },
        ],
      },
      {
        path: '/applications',
        component: ApplicationLayoutWrapper,
        requirements: ['emailVerified'],
        routes: [
          {
            path: '/applications',
            component: UnitApplicationsPage,
            requirements: ['emailVerified'],
          },
        ],
      },
    ],
  },
];

const ErrorBoundary: FC = () => {
  const error: any = useRouteError();
  if (error && error.status === 404 && error.internal) {
    return <NoFoundPage />;
  }

  SentryService.trackError(error);
  return <ErrorPage />;
};

const generateRouterRoute: (routesList: PropifyRoute[]) => RouteObject[] = (routesList) => {
  return routesList.map((route) => ({
    path: route.path,
    element: route.redirect ? (
      <Navigate to={route.redirect} />
    ) : route.component ? (
      <Suspense fallback={<PageLoading />}>
        <route.component />
      </Suspense>
    ) : (
      <Outlet />
    ),
    children: !route.redirect && route.routes ? generateRouterRoute(route.routes) : undefined,
    ErrorBoundary,
  }));
};

const Root: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useLayoutEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      ReactGA.pageview(location.pathname + location.search);
    }
  }, [location.pathname, location.search]);

  useLayoutEffect(() => {
    if (
      location.pathname === getHistory().location.pathname &&
      location.search === getHistory().location.search &&
      location.hash === getHistory().location.hash
    ) {
      return;
    }
    getHistory().replace(location.pathname + location.search + location.hash);
  }, [location.pathname, location.search, location.hash]);

  useLayoutEffect(() => {
    return getHistory().listen((change: Update) => {
      if (
        location.pathname === change.location.pathname &&
        location.search === change.location.search &&
        location.hash === change.location.hash
      ) {
        return;
      }
      navigate(change.location.pathname + change.location.search + change.location.hash, {
        replace: true,
      });
    });
  }, [location.pathname, location.search, location.hash, navigate]);

  return <Outlet />;
};

const router = SentryService.sentryCreateBrowserRouter([
  {
    path: '/',
    element: <Root />,
    children: generateRouterRoute(routes),
    ErrorBoundary,
  },
]);

export default router;
